<template>
	<div>
		<section class="breadcrumb-area">
            <!-- <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-area-content">
                            <h1>.</h1>
                        </div>
                    </div>
                </div>
            </div> -->
        </section><!-- breadcrumb area end -->
		<section class="popup-area pt-60">
			<div class="container">
				<div class="row flexbox-center pb-3">
					<div class="col-lg-6 text-center text-lg-left">
						<div class="section-title">
							<h1><i class="icofont icofont-film"></i> Treylerlar</h1>
						</div>
					</div>
					<div class="col-lg-6 text-center text-lg-right">
						<div class="popup-menu">
						</div>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col-lg-12">
						<div class="row popup-item">
							<div class="col-md-4 col-sm-6" v-for="(key, index) in list" :key="index">
								<div class="single-popup background-grayed border-radius-10 p-10 mb-30">
									<div class="single-popup-img">
										<img :src="$store.state.fileUrl + `/trillers/` + key.photo" alt="trialer" />
										<a :href="key.link" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="popup-content d-flex align-items-center justify-content-center">
										<h2>{{ key.name }}</h2>
									</div>
									<div class="popup-review d-flex justify-content-around pb-2 pt-2">
										<div class="popup-review-view">
											<i class="fa fa-eye"></i> {{ key.count }}
										</div>
										<h4><i class="fa fa-calendar"></i> {{ parseDate(key.createDate) }}</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pt-60 d-flex justify-content-center" v-if="this.pages > 1">
					<paginate 
						v-model="getPage"
						:page-count="pages"
						:page-range="3"
						:margin-pages="2"
						:click-handler="clickCallback"
						:prev-text="'＜'"
						:next-text="'＞'"
						:container-class="'pagination'"
						:page-class="'page-item'">
					</paginate>
					<form @submit.prevent="clickCallback(getPage)" >
						<input v-model="getPage" type="number" class="page-input" />
					</form>
				</div>
			</div>
		</section>

	</div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
	components: {
		Paginate
	},
	data() {
		return {
			list: [],
			limit: 10,
			offset: 0,
			pages: 0,
			getPage: 1,
		};
	},
	methods: {
		getData() {
			this.$api.get("/apiData/Triller/Get?" + 
				"offset=" + this.offset +
				"&limit=" + this.limit)
			.then(response => {
				this.list = response.result.data;
				this.pages = Math.ceil(response.result.count / this.limit);
				this.$nextTick(() => {
					this.initializeMagnificPopup();
				});
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		clickCallback(page) {
			this.offset = (page - 1) * this.limit
			this.getData()
		},
		initializeMagnificPopup() {
			// Initialize magnificPopup for YouTube videos
			$('.popup-youtube').magnificPopup({
				type: 'iframe',
				iframe: {
					patterns: {
						youtube: {
							index: 'youtube.com/',
							id: 'v=',
							src: 'https://www.youtube.com/embed/%id%?autoplay=1', // Autoplay on load
						},
					},
				},
			});
		},
		parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
	},
	mounted() {
		this.getData();
	},
};
</script>